import './App.css';
import {Route, Routes} from "react-router-dom";
import Login from "./pages/Login";
import {useEffect, useState} from "react";
import Start from "./pages/Start";
import Checkin from "./pages/Checkin";
import {Checkindone,} from "./pages/Checkindone";
import CustomerService from "./pages/CustomerService";
import Help from "./pages/Help";
import Checkout from "./pages/Checkout";
import {Checkoutdone} from "./pages/Checkoutdone";
import "../node_modules/flag-icons/css/flag-icons.css";

function App() {

    const [isLoggedIn, setisLoggedIn] = useState({})

    useEffect(() => {
        const loggedInUser = localStorage.getItem("user");
        if (loggedInUser) {
            const foundUser = JSON.parse(loggedInUser);
            setisLoggedIn(foundUser);
        }
    }, []);

    return (
        <Routes>
            <Route path="/" element={<Login/>}/>
            <Route path="/start" element={<Start/>}/>
            <Route path="/checkin" element={<Checkin/>}/>
            <Route path="/checkout" element={<Checkout/>}/>
            <Route path="/checkindone" element={<Checkindone/>}/>
            <Route path="/checkoutdone" element={<Checkoutdone/>}/>
            <Route path="/customerservice" element={<CustomerService/>}/>
            <Route path="/help" element={<Help/>}/>
        </Routes>
    );
}

export default App;
