import {FaBath, FaBed, FaChair, FaKitchenSet} from "react-icons/fa6";

export const items = [
    {
        id: 1,
        title: "Kök",
        icon: <FaKitchenSet/>,
        list: [
            {
                id: 1,
                text: "Är kylskåp och frys städat?",
                help: "Denna hjälptext är avsedd att ge användarna en översikt och vägledning.",
                showTextarea: true
            },
            {
                id: 2,
                text: "Är glas, bestick och porslin rengjorda?",
                help: "Denna hjälptext är avsedd att ge användarna en översikt och vägledning.",
                showTextarea: true
            },
            {
                id: 3,
                text: "Är diskmaskin, mikrovågsugn, köksspis, ugn och ugnsplåtar rengjorda?",
                help: "Denna hjälptext är avsedd att ge användarna en översikt och vägledning.",
                showTextarea: true
            },
            {
                id: 4,
                text: "Är kaffebryggare och brödrost rengjorda?",
                help: "Denna hjälptext är avsedd att ge användarna en översikt och vägledning.",
                showTextarea: true
            },
            {
                id: 5,
                text: "Är köksfläktilter rengjort?",
                help: "Denna hjälptext är avsedd att ge användarna en översikt och vägledning.",
                showTextarea: true
            },
            {
                id: 6,
                text: "Är avfallskärl och skåp rengjorda?",
                help: "Denna hjälptext är avsedd att ge användarna en översikt och vägledning.",
                showTextarea: true
            },
        ]
    },
    {
        id: 2,
        title: "Sovrum",
        icon: <FaBed/>,
        list: [
            {
                id: 1,
                text: "Är Skåp, lådor och garderober rengjorda?",
                help: "Denna hjälptext är avsedd att ge användarna en översikt och vägledning.",
                showTextarea: true
            },
            {
                id: 2,
                text: "Är sängkläderna på sängarna vikta?",
                help: "Denna hjälptext är avsedd att ge användarna en översikt och vägledning.",
                showTextarea: true
            },
        ]
    },
    {
        id: 3,
        title: "Badrum",
        icon: <FaBath/>,
        list: [
            {
                id: 1,
                text: "Är handfat, toalett rengjorda?",
                help: "Denna hjälptext är avsedd att ge användarna en översikt och vägledning.",
                showTextarea: true
            },
            {
                id: 2,
                text: "Är tvättställ och vattenkranar rengjorda?",
                help: "Denna hjälptext är avsedd att ge användarna en översikt och vägledning.",
                showTextarea: true
            },
            {
                id: 3,
                text: "Är kakel och speglar rengjorda?",
                help: "Denna hjälptext är avsedd att ge användarna en översikt och vägledning.",
                showTextarea: true
            },
            {
                id: 4,
                text: "Är golvbrunn i dusch och bastu rengjorda?",
                help: "Denna hjälptext är avsedd att ge användarna en översikt och vägledning.",
                showTextarea: true
            },
        ]
    },
    {
        id: 4,
        title: "Allmänna utrymmen",
        icon: <FaChair/>,
        list: [
            {
                id: 1,
                text: "Är soporna slängda?",
                help: "Denna hjälptext är avsedd att ge användarna en översikt och vägledning.",
                showTextarea: true
            },
            {
                id: 2,
                text: "Är golven skurade?",
                help: "Denna hjälptext är avsedd att ge användarna en översikt och vägledning.",
                showTextarea: true
            },
            {
                id: 3,
                text: "Är torkskåp rengjorda?",
                help: "Denna hjälptext är avsedd att ge användarna en översikt och vägledning.",
                showTextarea: true
            },
            {
                id: 4,
                text: "Är öppen spis rengjord?",
                help: "Denna hjälptext är avsedd att ge användarna en översikt och vägledning.",
                showTextarea: true
            }
        ]
    },
]
