import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {BsCheckCircleFill, BsXCircleFill} from "react-icons/bs";
import {Dialog, DialogBackdrop, DialogPanel} from "@headlessui/react";
import {RotatingLines} from "react-loader-spinner";
import {useTranslation} from "react-i18next";

const items = [
    {
        text: "Is the cleaning satisfactory?",
        help: "Ensure the apartment is thoroughly cleaned, including all rooms, appliances, and fixtures.",
        showTextarea: true
    },
    {
        text: "Is the apartment free from damages?",
        help: "Conduct a walkthrough to look for any damages that need to be addressed.",
        showTextarea: true
    },
    {
        text: "Is everything in place?",
        help: "Verify that all furnished items listed in the inventory are present and in good condition.",
        showTextarea: true
    },
    {
        text: "Is the snow removed?",
        help: "Is the path to the house free from snow?",
        showTextarea: false
    },
]

function ListItem2({item, index, setShowNumber, showNumber, ...rest}) {
    const [showTextArea, setShowTextArea] = useState(false);
    const [isOpen, setIsOpen] = useState(false)
    const {t, i18n} = useTranslation();

    const yesClickHandler = (e) => {
        e.preventDefault();
        showTransition()
            .then(() => {
                setShowNumber(showNumber + 1)
            })

    }
    const noClickHandler = (e) => {
        e.preventDefault();
        if (item.showTextarea) {
            setShowTextArea(true)
        } else {
            showTransition()
                .then(() => {
                    setShowNumber(showNumber + 1)
                })
        }

    }
    const commentClickHandler = (e) => {
        e.preventDefault();
        showTransition()
            .then(() => {
                setShowNumber(showNumber + 1)
                setShowTextArea(false)
            })
    }

    const showTransition = () => {
        setIsOpen(true)
        return new Promise(resolve => setTimeout(() => resolve(setIsOpen(false)), 300))
    }


    if (index === showNumber) {
        return (
            <div className={"text-center mt-2"}>
                <div className={"font-bold text-blue-800"}>{index + 1} {t("of")} {items.length}</div>

                <div className={"font-bold text-xl"}>{item.text}</div>
                <div className={"w-64 text-center m-auto mt-2"}>{item.help}</div>
                <div className={`${showTextArea ? "block" : "hidden"} mt-10`}>
                    <p className={"font-bold mb-2"}>{t("Checkin_form_message")}</p>
                    <textarea id="message" rows="4" className={` m-auto w-96 block p-2.5  text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500  dark:focus:border-blue-500`} placeholder={t("please_leave_a_comment")}></textarea>
                    <button onClick={commentClickHandler} className={"button m-auto  inline-flex mt-6 items-center justify-center w-96 px-6 py-2 mb-3 text-base font-medium text-center text-white  rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-300 dark:hover:bg-blue-700 md:mb-0"}>{t("Save")}</button>
                </div>

                <div className={`${!showTextArea ? "block" : "hidden"} flex justify-center align-middle gap-32 absolute bottom-10 left-1/2 transform -translate-x-1/2 -translate-y-1/2`}>
                    <button onClick={noClickHandler}>
                        <BsXCircleFill className={'drop-shadow-md h-14 w-14 bg-white rounded-full text-[#b72519] hover:text-[#b72519]/80'}/><span className={"font-bold"}>{t("NO")}</span>
                    </button>
                    <button onClick={yesClickHandler}>
                        <BsCheckCircleFill className={'drop-shadow-md h-14 shadow w-14 bg-white rounded-full text-[#5dc5b7] hover:text-[#5dc5b7]/80'}/><span className={"font-bold"}>{t("YES")}</span>
                    </button>
                </div>


                <Dialog open={isOpen} onClose={() => null} className="relative z-50">
                    <DialogBackdrop className="fixed inset-0 bg-black/30"/>
                    <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
                        <DialogPanel className="max-w-lg space-y-4 bg-white p-8 rounded shadow">
                            <RotatingLines
                                visible={true}
                                height="32"
                                width="32"
                                color="blue"
                                strokeWidth="5"
                                animationDuration="0.90"
                                ariaLabel="rotating-lines-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                            />
                        </DialogPanel>
                    </div>
                </Dialog>
            </div>
        )
    } else return (<></>)
}

export default function Form() {
    const navigate = useNavigate()
    const [showNumber, setShowNumber] = useState(0);

    const [formData, setFormData] = useState({})

    useEffect(() => {
        if (showNumber === items.length) {
            navigate("/checkindone")
        }
    }, [showNumber])

    return (
        <form>
            <fieldset>
                <legend className="sr-only">Check in list</legend>
                {items.map((item, i) => <div key={item.text}><ListItem2 setShowNumber={setShowNumber} showNumber={showNumber} index={i} item={item}/></div>)}
            </fieldset>
            {/*<button href={"/checkinsubmit"} className="block mt-4 items-center justify-center w-full sm:w-44 px-6 py-2 mb-3 text-base font-medium text-center text-white bg-button dark:bg-blue-600 hover:bg-button/80 rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-300 dark:hover:bg-blue-700 md:mr-5 md:mb-0">Submit</button>*/}
        </form>
    )
}


