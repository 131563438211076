import Header from "./Header";
import Footer from "./Footer";
import {useEffect, useState} from "react";
import QrReader from "../components/QrReader";
import {QrCodeIcon} from "@heroicons/react/16/solid";
import {Dialog, DialogPanel, DialogTitle} from "@headlessui/react";
import {useTranslation} from "react-i18next";

export default function Start() {


    const [isCheckedIn, setIsCheckedIn] = useState(false);
    let [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        setIsCheckedIn(JSON.parse(localStorage.getItem("isCheckedIn")))
    }, []);

    const { t, i18n } = useTranslation();


    function close() {
        setIsOpen(false)
    }
    const onPermissionError = () => {
        setIsOpen(false)
    }
    return (

        <div>
            <Header title={t("Start_welcome_to_title")} subtitle={t("Start_welcome_to_sub_title")}/>
            <main>
                <div className="bg-amber-50_ mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8 md:w-128 ml-auto mr-auto w-full">
                    {isCheckedIn ? (
                    <div>
                        <p className={"font-bold"}>{t("Start_you_are_checked_in_on")}:</p>
                        <p className={""}><span className={"font-bold"}>{t("Address")}:</span> Askebyn: Askestigen 32</p>
                        <p className={"mb-4"}><span className={"font-bold"}>{t("Housenumber")}:</span> 323B</p>

                        <p className={"font-bold"}>{t("Start_checkout")}</p>
                        <p>{t("Start_checkout_sub")}</p>

                        <a href={"/checkout"} className="button block mt-6 items-center justify-center w-full sm:w-64 px-6 py-2 mb-3 text-base font-medium text-center text-white rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-300 dark:hover:bg-blue-700 md:mr-5 md:mb-0">{t("btn_Start_checkout")}</a>
                    </div>
                    ) : (
                    <div>
                        <p className={"font-bold mt-2"}>{t("Start_checkin")}</p>
                            <p>{t("Start_checkin_sub")}</p>


                            <button className={"button inline-flex mt-6 items-center justify-center w-full sm:w-64 px-6 py-2 mb-3 text-base font-medium text-center text-white  rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-300 md:mr-5 md:mb-0"} onClick={() => setIsOpen(!isOpen)}>
                                <QrCodeIcon className={"w-4 h-4 me-2"}/>
                                {t("btn_Start_checkin")}
                            </button>

                            <p className={"mt-4"}><a className={"text-blue-800 hover:text-red-800"} href={"/checkin"}>{t("Start_checkin_link")}</a></p>


                        </div>
                    )}
                </div>
                <Dialog open={isOpen} as="div" className="relative z-10 focus:outline-none" onClose={close}>
                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4_">
                            <DialogPanel
                                transition
                                className="w-full _max-w-md rounded-sm bg-gray-100 p-6 backdrop-blur-2xl_ duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0"
                            >
                                <DialogTitle as="h3" className="text-base/7 font-medium ">
                                    <button className={" button inline-flex mt-6 items-center justify-center w-full sm:w-64 px-6 py-2 mb-3 text-base font-medium text-center text-white  rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-300 dark:hover:bg-blue-700 md:mr-5 md:mb-0"} onClick={() => setIsOpen(false)}>
                                            <QrCodeIcon className={"w-4 h-4 me-2"}/>
                                            {isOpen ? t("Close") : t("Open")}
                                        </button>
                                </DialogTitle>
                                <div className="mt-2 text-sm/6 text-white/50">
                                    <QrReader onPermissionError={onPermissionError}/>
                                </div>
                                <div className="mt-4">

                                </div>
                            </DialogPanel>
                        </div>
                    </div>
                </Dialog>
            </main>
            <Footer/>
        </div>
    )
}
