import Header from "./Header";
import Footer from "./Footer";
import List from "../components/checkout/Form";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

export default function Checkout() {

    const [qr, setQr] = useState(false)
    useEffect(() => {
        setQr(localStorage.getItem("object"))
    }, []);
    const { t, i18n } = useTranslation();

    return (
        <main>
            <Header title={t("Checkout_title")} subtitle={t("Checkout_sub_title")}/>

            <div className="bg-amber-50_ mx-auto max-w-7xl px-4 py-4 sm:px-6 lg:px-8 md:w-128 ml-auto mr-auto w-full">
                {/*<h2 className={"font-bold text-l text-center"}>Please take you time to fill out the form below.</h2>*/}
                <List/>
            </div>
            <Footer/>
        </main>
    )
}
